import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Logo from '../Logo';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import IconEmailActionUnread from '@cimpress-technology/react-streamline-icons/lib/IconEmailActionUnread';
import IconCog from '@cimpress-technology/react-streamline-icons/lib/IconCog';
import IconNavigationMenu from '@cimpress-technology/react-streamline-icons/lib/IconNavigationMenu';
import IconArrowDownAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowDownAlt';
import Profile from '../Profile';
import SideNav from './SideNav';
import Collapse from 'react-smooth-collapse';
import { Button, Label, Dropdown, NavTabItem, NavTab } from '@cimpress/react-components';
import { ACCOUNT_CLAIM } from '../services/account';
import { useBranding } from '../branding/useBranding';
import BrandingContext, { BrandingProvider } from '../branding/BrandingContext';
import { cx, css } from '@emotion/css';
import { navbarCss, hamburgerCss, logoCss, nonProdWarning, mobileSize, flexBreak } from './styles';
import useWindowSize from './useWindowSize';
import { StepupAuthentication } from './StepupAuthentication';

export const Header = (props) => {
  const { isBrandingLoading, brandingInfo } = useBranding(props.accessToken, props.useBranding, props.sessionId);

  const branding = {
    shouldUse: props.useBranding,
    data: !isBrandingLoading && (props.accessToken || props.sessionId) ? brandingInfo : null,
  };

  return (
    <BrandingProvider value={branding}>
      <BaseHeader {...props} />
    </BrandingProvider>
  );
};

const BaseHeader = ({
  appTitle,
  appLinks = [],
  notifications,
  documentationLink,
  mailToAddress,
  appId,
  showLeftNav,
  showLogo = true,
  language = 'en',
  environment = 'prd',
  isProduction = true,
  responsive = true,
  profile,
  onSettingsClicked,
  onLogOutClicked,
  onLogInClicked,
  isLoggedIn,
  accessToken,
  sessionId,
  enableStepUpAuthentication,
  onStepUpAuthenticationConfirm,
  getStepUpExpiryTime,
}) => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [forceMobile, setForceMobile] = useState(false);
  const branding = useContext(BrandingContext);
  const windowWidth = useWindowSize().width;

  const fluidNavTitle = useRef(null);
  const fluidNavLinks = useRef(null);

  const titleWidthRef = useRef(0);
  const linksWidthRef = useRef(0);

  const toggleSideNav = () => (sideNavOpen ? setSideNavOpen(false) : setSideNavOpen(true));

  useEffect(() => {
    if (!responsive) {
      return;
    }

    const currentTitleWidth = fluidNavTitle.current?.offsetWidth ?? 0;
    const currentLinksWidth = fluidNavLinks.current?.offsetWidth ?? 0;

    // We need to remember desktop values on a mobile layout, to know when to switch back to the desktop layout

    if (currentTitleWidth !== 0) {
      titleWidthRef.current = currentTitleWidth;
    }

    if (currentLinksWidth !== 0) {
      linksWidthRef.current = currentLinksWidth;
    }

    const shouldForceMobile = windowWidth <= titleWidthRef.current + linksWidthRef.current + 40 + 60; //padding

    setForceMobile(shouldForceMobile);
  }, [windowWidth, responsive]);

  const navLinks = (
    <NavTab
      onMouseDown={(e) => (mobileNavOpen && forceMobile ? e.preventDefault() : undefined)}
      className={cx('mcp-nav-tabs', { 'mcp-nav-stacked': forceMobile })}
      vertical={forceMobile}
      style={forceMobile ? { paddingBottom: 10 } : {}}
    >
      {appLinks.map((obj) => {
        const { id, type = 'default', isActive, content, title } = obj;
        const className = cx({ active: isActive });
        return type === 'dropdown' ? (
          <Dropdown className={className} key={id} variant="navtab" title={title}>
            {content}
          </Dropdown>
        ) : (
          <NavTabItem className={className} key={id}>
            {content}
          </NavTabItem>
        );
      })}
      {(onSettingsClicked || documentationLink || mailToAddress) && !forceMobile ? (
        <li className={'link-divider'} />
      ) : null}
      {onSettingsClicked ? (
        <li className={'icon-link'} key="settings-link">
          <a onClick={onSettingsClicked} className={'clickable'}>
            <IconCog />
          </a>
        </li>
      ) : null}
      {documentationLink ? (
        <li className="icon-link" key="documentation-link">
          <a aria-label="Documentation" href={documentationLink} className={'clickable'}>
            <IconBookOpen />
          </a>
        </li>
      ) : null}
      {mailToAddress ? (
        <li className={'icon-link'} key="mailtoAddress-link">
          <a aria-label="Contact Email" href={`mailto:${mailToAddress}`} className={'clickable'}>
            <IconEmailActionUnread />
          </a>
        </li>
      ) : null}
    </NavTab>
  );

  const showNav = showLeftNav && (!branding.shouldUse || (branding.data && branding.data.header.sideNav.enabled));

  const fluidNav = (
    <div className={cx('navbar-fluid', { [mobileSize]: forceMobile })}>
      <nav
        className={cx('mcp-navbar', 'mcp-navbar-subtitle', navbarCss, { [nonProdWarning]: !isProduction })}
        data-ref={'fluidNav'}
      >
        <div className={'navbar-header'} ref={fluidNavTitle}>
          <h2>{appTitle}</h2>
          {isProduction ? null : (
            <Label
              className={css({ marginLeft: '10px', position: 'relative', top: '-3px' })}
              status="danger"
              text="Non-Production"
            />
          )}
        </div>
        {forceMobile ? (
          <>
            <span
              className={cx('navbar-toggle')}
              onClick={() => (mobileNavOpen ? setMobileNavOpen(false) : setMobileNavOpen(true))}
              style={forceMobile ? { outline: 'none', paddingBottom: 10 } : {}}
              tabIndex="2"
              onBlur={() => setMobileNavOpen(false)}
            >
              Menu <IconArrowDownAlt className="caret" />
            </span>
            <div className={flexBreak} />
            <Collapse expanded={mobileNavOpen}>{navLinks}</Collapse>
          </>
        ) : null}
        <div className={'crc-header-nav-links'} ref={fluidNavLinks}>
          {navLinks}
        </div>
      </nav>
    </div>
  );

  return (
    <div>
      {showNav ? (
        <SideNav
          sideNavOpen={sideNavOpen}
          toggleSideNav={toggleSideNav}
          language={language}
          environment={environment}
          activeNavLinkId={appId}
          accessToken={accessToken}
          sessionId={sessionId}
        />
      ) : null}
      <nav className={cx('mcp-navbar', navbarCss)}>
        {showNav ? (
          <Button
            className={hamburgerCss}
            onClick={() => setSideNavOpen(true)}
            icon={<IconNavigationMenu size="2x" />}
            data-fullstoryid="react-platform-header-hamburger-menu-button"
          />
        ) : null}
        <div className={cx(logoCss, { [css({ marginLeft: '0px' })]: !showNav })}>
          {showLogo ? <Logo width={'107px'} /> : null}
        </div>
        <StepupAuthentication
          isLoggedIn={isLoggedIn}
          enableStepUpAuthentication={enableStepUpAuthentication}
          onStepUpAuthenticationConfirm={onStepUpAuthenticationConfirm}
          getStepUpExpiryTime={getStepUpExpiryTime}
        />
        {notifications}
        <div
          className={css`
            position: relative;
          `}
        >
          <Profile
            profile={profile}
            isLoggedIn={isLoggedIn}
            onLogInClicked={onLogInClicked}
            onLogOutClicked={onLogOutClicked}
          />
        </div>
      </nav>
      {fluidNav}
    </div>
  );
};

Header.propTypes = {
  appTitle: PropTypes.node.isRequired,
  appLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['default', 'dropdown']),
      content: PropTypes.node,
      title: PropTypes.string,
      isActive: PropTypes.bool,
    })
  ),
  notifications: PropTypes.node,
  documentationLink: PropTypes.node,
  mailToAddress: PropTypes.node,
  appId: PropTypes.string,
  showLeftNav: PropTypes.bool,
  showLogo: PropTypes.bool,
  language: PropTypes.string,
  environment: PropTypes.string,
  isProduction: PropTypes.bool,
  responsive: PropTypes.bool,
  profile: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    domain: PropTypes.string,
    sub: PropTypes.string,
    [ACCOUNT_CLAIM]: PropTypes.string,
  }),
  onSettingsClicked: PropTypes.func,
  onLogOutClicked: PropTypes.func.isRequired,
  onLogInClicked: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  accessToken: PropTypes.string,
  sessionId: PropTypes.string,
  enableStepUpAuthentication: PropTypes.bool,
  onStepUpAuthenticationConfirm: PropTypes.func,
  getStepUpExpiryTime: PropTypes.func,
};
BaseHeader.propTypes = Header.propTypes;
