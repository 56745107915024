import { useQuery } from '@tanstack/react-query';

import {
  getItemWithCartEvaluationDetails,
  getItemWithDeliveryRequest,
  ItemWithCartEvaluation,
  ItemWithDeliveryDetails,
} from '../services/items';

type UseItemInput = {
  itemId: string;
  timezone: string | undefined;
  enabled: boolean;
  onSuccess: ((data: ItemWithDeliveryDetails) => void) | undefined;
};

type UseItemWithCartEvaluationInput = {
  itemId: string;
  timezone: string | undefined;
  enabled: boolean;
  onSuccess: ((data: ItemWithCartEvaluation) => void) | undefined;
};

export const useItem = ({ itemId, timezone, enabled, onSuccess }: UseItemInput) => {
  return useQuery({
    queryKey: ['item', itemId, timezone],
    queryFn: () => getItemWithDeliveryRequest(itemId, timezone),
    enabled: !!timezone && !!itemId && enabled,
    retry: false,
    onSuccess,
  });
};

export const useItemWithCartEvaluation = ({ itemId, timezone, enabled, onSuccess }: UseItemWithCartEvaluationInput) => {
  return useQuery({
    queryKey: ['item', itemId, timezone],
    queryFn: () => getItemWithCartEvaluationDetails(itemId, timezone),
    enabled: !!timezone && !!itemId && enabled,
    retry: false,
    onSuccess,
  });
};
