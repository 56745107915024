import { useMutation, useQuery } from '@tanstack/react-query';

import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient';

import { AppSettings, GlobalSettings } from '../components/types';
import { APP_SETTINGS_QUERY_KEY, GLOBAL_SETTINGS_QUERY_KEY } from '../constants';
import { getAppSettings, updateAppSettings } from '../services/customizr';

export const useAppSettings = ({ onSuccess }: { onSuccess?: (data: AppSettings) => void }) => {
  return useQuery({
    queryKey: [APP_SETTINGS_QUERY_KEY],
    queryFn: getAppSettings,
    onSuccess,
  });
};

export const useNewAppSettings = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation({
    mutationFn: updateAppSettings,
    onSuccess,
  });
};

export const useGlobalSettings = ({
  authToken,
  onSuccess,
}: {
  authToken: string;
  onSuccess?: (data: GlobalSettings) => void;
}) => {
  return useQuery({
    queryKey: [GLOBAL_SETTINGS_QUERY_KEY],
    queryFn: () => getSettings(authToken) as Promise<GlobalSettings>,
  });
};
