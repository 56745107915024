import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-smooth-collapse';
import NavItem from './NavItem';

export default class NavItemExpandable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { expanded } = this.state;
    const { item } = this.props;
    return (
      <div>
        <NavItem active={expanded} onClick={this.toggle} {...this.props} />
        <Collapse expanded={expanded}>
          {item.subItems.map(item => <NavItem nested key={item.id} item={item} />)}
        </Collapse>
      </div>
    );
  }
}

NavItemExpandable.propTypes = {
  item: PropTypes.object,
};
