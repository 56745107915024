import { useQuery } from '@tanstack/react-query';

import { TracingRecord, getTracingRecord } from '../services/itemDeliveryPossibilities';

type UseIDPTracingRecordInput = {
  idpTracingRecordId: string;
  enabled: boolean;
  onSuccess: ((data: TracingRecord) => void) | undefined;
};

export const useIDPTracingRecord = ({ idpTracingRecordId, enabled, onSuccess }: UseIDPTracingRecordInput) => {
  return useQuery({
    queryKey: ['idpTracingRecord', idpTracingRecordId],
    queryFn: () => getTracingRecord(idpTracingRecordId),
    enabled,
    retry: false,
    onSuccess,
  });
};
