import { useQuery } from '@tanstack/react-query';

import { fetchWithAuth } from '../services/fetchWithAuth';

type Attribute = {
  class: string;
  attributeKey: string;
  attributeValue: string;
};

export type ProductConfiguration = {
  mcpSku: string;
  attributes: Attribute[];
  productVersion?: string;
};

export const useProductConfiguration = (productConfigurationUrl: string) => {
  return useQuery({
    queryKey: ['productConfiguration', productConfigurationUrl],
    queryFn: () => fetchWithAuth<ProductConfiguration>({ endpointUrl: productConfigurationUrl }),
    enabled: !!productConfigurationUrl,
  });
};
