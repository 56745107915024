import { useQuery } from '@tanstack/react-query';

import { DeliveryOptionResponse, getDeliveryOptionsByGroupId } from '../services/edo';

const useDeliveryOptions = ({
  groupId,
  onError,
  onSuccess,
}: {
  groupId: string;
  onError?: (error: Error) => void;
  onSuccess?: (data: DeliveryOptionResponse[]) => void;
}) => {
  return useQuery({
    queryKey: ['delivery-options', groupId],
    queryFn: () => getDeliveryOptionsByGroupId({ groupId }),
    onError,
    onSuccess,
  });
};

export default useDeliveryOptions;
