import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, colors } from '@cimpress/react-components';
import { ACCOUNT_CLAIM, ACCOUNTS_MANAGEMENT_DASHBOARD_URL, fetchAccountDetails } from './services/account';
import { css, cx } from '@emotion/css';

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const avatarCss = css`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 10px;
  vertical-align: middle;
`;

const popoverAvatarCss = css`
  border: none;
  vertical-align: middle;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin-top: -5px;
`;

const popoverContentCss = css`
  box-shadow: none;
  padding: 8px 0;
  text-align: center;
`;

const btnBrandPrimary = css`
  color: ${colors.white};
  background: ${colors.persimmon.base};
  border: 1px solid ${colors.persimmon.base};

  &:hover {
    color: ${colors.white};
    background: ${colors.persimmon.darker};
    border: 1px solid ${colors.persimmon.darker};
  }
  &:active {
    color: ${colors.white};
    background: ${colors.persimmon.darkest};
    border: 1px solid ${colors.persimmon.darkest};
  }
`;

const btnBrandOutline = css`
  color: ${colors.persimmon.base};
  background: ${colors.white};
  border: 1px solid ${colors.persimmon.base};
`;

const profileTextCss = css`
  display: flex;
  align-items: center;
  &,
  &:hover,
  &:active {
    color: ${colors.cobalt.base};
    text-decoration: none;
  }
`;

export default class ProfilePop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      viewportWidth: 0,
      fetchingAccountInfo: false,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggle = this.toggle.bind(this);
    this.fetchingAccountDetails = this.fetchingAccountDetails.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    const { profile: { [ACCOUNT_CLAIM]: account } = {}, accessToken, sessionId } = this.props;
    this.fetchingAccountDetails({ account, accessToken, sessionId });
  }

  componentDidUpdate(prevProps) {
    const { profile: { [ACCOUNT_CLAIM]: prevAccount } = {} } = prevProps;
    const { profile: { [ACCOUNT_CLAIM]: account } = {}, accessToken, sessionId } = this.props;
    if (prevAccount !== account) this.fetchingAccountDetails({ account, accessToken, sessionId });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ viewportWidth: window.outerWidth });
  }

  handleClose() {
    this.state.open && this.setState({ open: false });
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  fetchingAccountDetails({ account, accessToken, sessionId }) {
    if (account && (accessToken || sessionId)) {
      this.setState({ fetchingAccountInfo: true });
      fetchAccountDetails({ account, accessToken, sessionId }).then(({ name: accountName }) => {
        this.setState({ accountName, fetchingAccountInfo: false });
      });
    }
  }

  render() {
    let { isLoggedIn, profile, onLogInClicked, onLogOutClicked, authToken, environment } = this.props;
    let { [ACCOUNT_CLAIM]: account, email, name, picture, domain } = profile || {};
    const { accountName, fetchingAccountInfo } = this.state;
    name = name || 'Unknown';

    if (!domain) {
      const emailDomain = email ? capitalize(email.replace(/.*@/, '').split('.')[0]) : '';
      domain = account && fetchingAccountInfo ? '' : accountName || emailDomain;
    }

    if (!picture) {
      picture = `https://i2.wp.com/cdn.auth0.com/avatars/${name[0]}.png?ssl=1`;
    }

    if (!isLoggedIn) {
      return (
        <Button onClick={onLogInClicked} className={cx(btnBrandPrimary, btnBrandOutline)}>
          Log In
        </Button>
      );
    }

    let profileContents = (
      <div
        className={css`
          text-align: center;
        `}>
        <div className={popoverContentCss}>
          <img className={popoverAvatarCss} src={picture} />
          <div
            className={css`
              margin-bottom: 5px;
              font-size: 17px;
            `}>
            {name}
          </div>
          <div
            className={css`
              margin-bottom: 2px;
            `}>
            {email}
          </div>
          {account &&
            accountName && (
              <div
                className={css`
                  margin-bottom: 2px;
                `}>
                <a target="_blank" rel="noopener noreferrer" href={`${ACCOUNTS_MANAGEMENT_DASHBOARD_URL}/${account}`}>
                  {accountName}
                </a>
              </div>
            )}
        </div>
        <div className={popoverContentCss}>
          <Button blockLevel onClick={onLogOutClicked} className={btnBrandPrimary}>
            Log Out
          </Button>
        </div>
      </div>
    );
    return (
      <div className="mcp-navbar-userbox">
        <Tooltip
          variant="popover"
          show={this.state.open}
          onClickOutside={this.handleClose}
          direction="bottom"
          constraints={[
            {
              to: 'scrollParent',
              attachment: 'together',
              pin: ['right', 'top'],
            },
          ]}
          contents={profileContents}>
          <Fragment>
            <Button variant="anchor" className={profileTextCss} onClick={this.toggle}>
              {this.state.viewportWidth > 500 ? (
                <span>
                  <div>{name}</div>
                </span>
              ) : null}
              <img className={avatarCss} src={picture} />
            </Button>
          </Fragment>
        </Tooltip>
      </div>
    );
  }
}

ProfilePop.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    domain: PropTypes.string,
    sub: PropTypes.string,
    [ACCOUNT_CLAIM]: PropTypes.string,
  }),
  onLogOutClicked: PropTypes.func.isRequired,
  onLogInClicked: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
};
