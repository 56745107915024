import { useQuery } from '@tanstack/react-query';

import { getProduct } from '../services/introduction';

export const useProduct = (productConfigurationUrl: string | undefined) => {
  return useQuery({
    queryKey: ['product', productConfigurationUrl],
    queryFn: () => getProduct({ productConfigurationUrl }),
    enabled: !!productConfigurationUrl,
  });
};
